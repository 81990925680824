/*------------------------------------*\
    Page Template: Carrello, Spedizione, Riepilogo, Conferma ordine, Ordini, Dettaglio ordine
    carrello.php spedizione-php, riepilogo.php, conferma-ordine.php, ordini.php, ordine-dettaglio.php
    .page-template-carrello, .page-template-spedizione, .page-template-riepilogo, .page-template-conferma-ordine, .page-template-ordini, .page-template-ordine-dettaglio
\*------------------------------------*/

.page-template-carrello,
.page-template-spedizione,
.page-template-riepilogo,
.page-template-conferma-ordine,
.page-template-ordini,
.page-template-ordine-dettaglio {

    .box--intro {
        margin: 0 auto;
        max-width: $w-media-uw;
        padding-bottom: $gutter / 2;
    }

    .box--section-1 {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        &::after {
            width: 30%;
            height: 184px;
            position: absolute;
            right: 0;
            top: - $gutter * 4;
            content: '';
            background: url('../images/griglia_contatti.svg') no-repeat center center / contain;

            @media #{$media-sm} {
                display: none;
            }
        }

        .col-1 {
            width: 100%;
            margin: 0 auto;
            margin-top: $gutter;
            padding: 0 $gutter * 2;
            max-width: $w-media-u;
            display: flex;
            align-items: center;
            gap: 1px;

            @media #{$media-b} {
                padding: 0;
            }

            @media #{$media-s} {
                flex-wrap: wrap;
                margin-top: $gutter / 4;
            }

            .fase {
                flex: 1;
                background: linear-gradient(to bottom, var(--c-accent) 0%, #ffdf93 100%);
                border: 1px solid var(--c-grey-dark);
                font-family: $f-family-title;
                font-weight: $f-weight-500;
                font-size: 1.8rem;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                gap: 30px;
                
                @media #{$media-sm} {
                    font-size: 1.6rem;
                    gap: 5px;
                }

                @media #{$media-s} {
                    font-size: 1.4rem;
                    gap: 2px;
                    flex: auto;
                    width: 48%;
                }

                &.selected {
                    background: var(--c-corporate-1);
                    color: var(--c-white);
                }

                .number {
                    font-size: 2.8rem;
                    font-family: $f-family-text;
                    padding: 0 $gutter / 4;

                    @media #{$media-sm} {
                        font-size: 2rem;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    width: 100%;
                    transition: $trans-default;

                    &:hover {
                        background-color: var(--c-corporate-1);
                        color: var(--c-white);
                    }
                }
            }
        }
    }

    // .box--section-1

    .box--section-2 {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            margin: 0 auto;
            margin-top: $gutter;
            padding: 0 $gutter * 2;
            max-width: $w-media-u;
            display: flex;
            justify-content: space-between;

            @media #{$media-b} {
                flex-direction: column;
                gap: 2rem;
                margin-top: $gutter / 2;
                padding: 0 0 $gutter 0;
            }

            .ricercaCarrello,
            .cambioData {
                span {
                    font-family: $f-family-text;
                    font-size: 1.8rem;

                    @media #{$media-s} {
                        font-size: 1.5rem;
                    }
                }

                .form {
                    display: flex;
                    margin-top: $gutter / 8;
                    position: relative;
                    color: var(--c-corporate-1);

                    input {
                        border: 1px solid var(--c-corporate-1);
                        padding: $gutter / 4 $gutter;

                        &::placeholder {
                            font-size: 1.3rem;
                            font-style: italic;
                        }

                        @media #{$media-s} {
                            width: 200px;
                            padding-right: 0;
                            font-size: 1.3rem;
                        }

                        @media #{$media-sx} {
                            width: 150px;
                            padding-left: $gutter / 4;
                        }
                    }

                    button {
                        background-color: var(--c-corporate-1);
                        border: 0;
                        font-size: 1.8rem;
                        text-transform: uppercase;
                        color: var(--c-corporate-5);
                        font-family: $f-family-title;
                        font-weight: $f-weight-600;
                        width: max-content;
                        min-width: 20rem;
                        padding: $gutter / 4;

                        @media #{$media-s} {
                            font-size: 1.5rem;
                            min-width: 16rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.3rem;
                            min-width: 14rem;
                        }
                    }

                    input[type="button"] {
                        display: none;
                    }

                    .facetwp-facet {
                        margin-bottom: 0;
                    }

                    .fcomplete-wrap {
                        z-index: 10;
                    }
                }
            }

            .ricercaCarrello {
                .form {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: url('../images/icon-search.svg') no-repeat 1rem center / 27px 25px;
                        pointer-events: none;
                    }

                    @media #{$media-sx} {
                        &::before {
                            display: none
                        }
                    }
                }
            }

            .cambioData {
                .form {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: url('../images/icon-calendar.svg') no-repeat 1rem center / 24px 23px;
                        pointer-events: none;
                    }

                    @media #{$media-sx} {
                        &::before {
                            display: none
                        }
                    }
                }
            }

        }
    }

    // .box--section-2

    .box--section-3 {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            margin: 0 auto;
            margin-top: $gutter;
            padding: 0 $gutter * 2;
            max-width: $w-media-u;
            display: flex;
            justify-content: space-between;

            @media #{$media-b} {
                margin-top: $gutter / 2;
                padding: 0;
                overflow-x: scroll;
            }

            #cartTable {
                width: 100%;
                font-family: $f-family-title;

                .colonnaPrezzo {
                    display: none;
                }

                @media #{$media-m} {
                    table-layout:fixed;
                }

                thead {
                    border-bottom: .1rem solid var(--c-grey-dark);

                    tr {
                        background-color: var(--c-corporate-5);

                        th {
                            border: .1rem solid var(--c-grey-dark);
                            vertical-align: middle;
                            font-size: 1.8rem;
                            line-height: 1;
                            color: var(--c-theme-4);
                            padding: $gutter / 4;

                            &.thTotal {
                                width: 10%;
                            }
                        }

                        .thProdotto {
                            @media #{$media-m} {
                                width: 200px;
                            }
                        }

                        .thDesc {
                            @media #{$media-m} {
                                width: 300px;
                            }
                        }

                        .thQty {
                            @media #{$media-m} {
                                width: 200px;
                            }
                        }

                        .thData {
                            @media #{$media-m} {
                                width: 200px;
                            }
                        }

                        .thDelete {
                            @media #{$media-m} {
                                width: 40px;
                            }
                        }

                        .thOrdine {
                            @media #{$media-m} {
                                width: 200px;
                            }
                        }
                        .thOrdineSmall {
                            @media #{$media-m} {
                                width: 140px;
                            }
                        }
                        
                    }
                }

                tbody {
                    tr {
                        border-bottom: .1rem solid var(--c-grey-dark);

                        &.summary {
                            border-left: .1rem solid var(--c-grey-dark);
                            border-right: .1rem solid var(--c-grey-dark);
                            border-bottom: .1rem solid var(--c-grey-dark);
                            background-color: var(--c-corporate-5);

                            td {
                                text-align: center;
                                font-weight: $f-weight-700;
                            }
                        }

                        td {
                            padding: $gutter / 4;
                            min-height: 55px;
                            vertical-align: middle;

                            &.modelPrice,
                            &.modelTotalPrice,
                            &.modelShippingDate,
                            &.modelShippingRealDate,
                            &.modelName,
                            &.deleteCartItem {
                                text-align: center;
                            }

                            &.modelName {
                                font-weight: $f-weight-700;

                                .openTooltip {
                                    background-color: var(--c-error);
                                    color: var(--c-white);
                                    padding: 0.2rem 0.8rem;
                                    text-decoration: line-through;

                                    .info {
                                        position: relative;

                                        &::after {
                                            content: '?';
                                            position: absolute;
                                            top: -1.4rem;
                                            right: -3rem;
                                            background-color: var(--c-grey-light);
                                            color: var(--c-corporate-1);
                                            width: 20px;
                                            height: 20px;
                                            border-radius: 50%;
                                            font-size: 1.3rem;
                                        }
    
                                        .tooltip {
                                            color: var(--c-corporate-1);
                                            font-weight: $f-weight-400;
                                        }
                                    }
                                }
                            }

                            &.modelDesc,
                            &.modelPrice {
                                span {
                                    text-decoration: line-through;
                                }
                            }

                            &.modelTotalPrice {
                                .linethrough {
                                    text-decoration: line-through;
                                }
                            }

                            &.modelQnt {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 5px;

                                input {
                                    width: 50px;
                                    text-align: center;
                                    padding: 0.6rem 0rem;
                                    pointer-events: none;
                                }

                                button {
                                    color: var(--c-white);
                                    background-color: var(--c-corporate-1);
                                    border: 0;
                                    width: 30px;
                                    height: 32px;
                                    font-size: 2rem;
                                }

                                .blocco_giacenza {
                                    width: 30px;
                                    height: 30px;
                                    margin-left: $gutter / 6;
                                    border-radius: 50%;
                                    position: relative;

                                    &.green {
                                        background-color: var(--c-electric);
                                    }

                                    &.orange {
                                        background-color: var(--c-accent);
                                    }

                                    &.red {
                                        background-color: var(--c-red);
                                    }

                                    .tooltip {
                                        span {
                                            display: none;

                                            &.visible {
                                                display: block;
                                            }
                                        }
                                    }

                                }
                            }

                            &.modelPrice {
                                .oldPrice {
                                    background-color: var(--c-accent);
                                    text-decoration: line-through;
                                    font-size: 1.4rem;
                                    padding: 2px;
                                }
                            }

                            &.modelShippingDate {
                                position: relative;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 37px;
                                    width: 25px;
                                    height: 100%;
                                    background: url('../images/icon-calendar.svg') no-repeat 0 center / 20px 20px;
                                    pointer-events: none;
                                }

                                &.hideCalendar {
                                    &::after {
                                        display: none;
                                    }
                                }

                                .shippingDate {
                                    width: 120px;
                                    border: none;
                                    font-family: $f-family-title;
                                    color: var(--c-corporate-1);
                                    cursor: pointer;
                                }
                            }

                            &.deleteCartItem {
                                .removeFromCart {
                                    display: flex;
                                    justify-content: center;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    // .box--section-3

    .box--section-4 {
        display: flex;
        position: relative;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .center {
            width: 100%;
            margin: 0 auto;
            margin-top: $gutter;
            padding: 0 $gutter * 2;
            max-width: $w-media-u;
            display: flex;
            flex-wrap: wrap;
            gap: $gutter;

            @media #{$media-b} {
                margin-top: $gutter / 4;
                padding: 0;
            }

            .col-1 {
                flex: 1;

                @media #{$media-m} {
                    flex: auto;
                    width: 100%;
                }

                textarea {
                    width: 100%;
                    height: $gutter * 3;
                    border: 1px solid var(--c-corporate-1);
                    color: var(--c-corporate-1);
                    padding: $gutter / 4 $gutter / 4;
                    resize: none;

                    &::placeholder {
                        font-size: 1.3rem;
                        font-style: italic;
                    }
                }
            }

            .col-2 {
                flex: 1;

                @media #{$media-s} {
                    flex: auto;
                    width: 100%;
                }

                input {
                    display: flex;
                    width: 60%;
                    border: 1px solid var(--c-corporate-1);
                    color: var(--c-corporate-1);
                    padding: $gutter / 4 $gutter / 4;
                }
            }

            .col-3 {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                button {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    height: 4.5rem;
                    padding-left: $gutter * .5;
                    padding-right: $gutter * 4;
                    border: 0;

                    font-family: $f-family-title;
                    font-weight: $f-weight-600;
                    font-size: 1.8rem;
                    line-height: 1;
                    text-transform: uppercase;
                    color: var(--c-corporate-1);
                    background-color: var(--c-oil);
                    transition: $trans-default;

                    @media #{$media-m} {
                        font-size: 1.6rem;
                    }

                    &::after {

                        display: inline-flex;
                        content: '';
                        width: 0;
                        height: 0;
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-left: 4.5rem solid transparent;
                        border-right: initial;
                        border-top: initial;
                        border-bottom: 4.5rem solid var(--c-white);

                    }

                    &:hover,
                    &:focus-within {
                        color: var(--c-white);
                        background-color: var(--c-corporate-1);
                    }
                }

                // button

                .link {
                    font-size: 1.4rem;
                    margin-top: $gutter;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
            }

            span {
                font-family: $f-family-text;
                font-size: 1.8rem;
            }
        }
    }

    // .box--section-4

    .box--section-5 {
        display: flex;
        position: relative;

        @media #{$media-u} {
            max-width: $w-media-uw;
        }

        .col-1 {
            width: 100%;
            margin: 0 auto;
            margin-top: $gutter;
            padding: 0 $gutter * 2;
            max-width: $w-media-u;
            display: flex;
            flex-direction: column;
            font-size: 2.4rem;

            a {
                text-decoration: underline;
            }
        }
    }

    // .box--section-5

}

// .page-template-carrello, .page-template-spedizione, .page-template-riepilogo, .page-template-conferma-ordine, .page-template-ordini, .page-template-ordine-dettaglio

.page-template-spedizione,
.page-template-riepilogo,
.page-template-conferma-ordine,
.page-template-ordini,
.page-template-ordine-dettaglio {
    .modelShippingDate {
        &::after {
            display: none;
        }
    }

    .box--section-4 {
        .center {
            .col-1 {
                flex: 2;

                .content {
                    display: flex;
                    align-items: center;
                    margin-bottom: $gutter / 2;

                    span {
                        font-size: 1.6rem;

                        &.text {
                            width: $gutter * 7;
                            font-weight: $f-weight-700;
                            font-size: 1.8rem;

                            @media #{$media-m} {
                                font-size: 1.6rem;
                                width: $gutter * 6;
                            }            
                        }
                    }

                    &.selectShipping {
                        flex-direction: column;
                        align-items: flex-start;

                        .elencoIndirizziSpedizione {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            margin-top: $gutter / 4;

                            .singleAddress {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                input {
                                    transform: scale(1.5);
                                }

                                &.error {
                                    background-color: var(--c-error-light);
                                }

                                &.selected {
                                    background-color: var(--c-electric-light);
                                }
                            }
                        }
                    }
                }
            }

            .col-4 {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}

//  .page-template-spedizione, .page-template-riepilogo, .page-template-conferma-ordine

.page-template-conferma-ordine {
    .box--section-4 {
        .center {
            .col-1 {
                width: 100%;
                flex: auto;

                h4 {
                    font-size: 3rem;
                    font-family: $f-family-text;
                    margin-bottom: $gutter;

                    @media #{$media-m} {
                        font-size: 2.4rem;
                    }

                    span {
                        background-color: var(--c-electric);
                        font-size: 3rem;

                        @media #{$media-m} {
                            font-size: 2.4rem;
                        }
                    }
                }
            }

            .col-3 {
                width: 100%;
                flex: auto;
                align-items: center;

                a {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    height: 4.5rem;
                    padding-left: $gutter * .5;
                    padding-right: $gutter * 4;
                    border: 0;

                    font-family: $f-family-title;
                    font-weight: $f-weight-600;
                    font-size: 1.8rem;
                    line-height: 1;
                    text-transform: uppercase;
                    color: var(--c-corporate-1);
                    background-color: var(--c-oil);
                    transition: $trans-default;

                    @media #{$media-m} {
                        font-size: 1.5rem;
                        padding-right: $gutter * 2;
                    } 

                    &::after {

                        display: inline-flex;
                        content: '';
                        width: 0;
                        height: 0;
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-left: 4.5rem solid transparent;
                        border-right: initial;
                        border-top: initial;
                        border-bottom: 4.5rem solid var(--c-white);

                    }

                    &:hover,
                    &:focus-within {
                        color: var(--c-white);
                        background-color: var(--c-corporate-1);
                    }
                }
            }

            .col-3,
            .col-4 {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}

// .page-template-conferma-ordine

.page-template-ordini {

    .box--section-3 {
        .col-1 {
            flex-direction: column;
        }
    }

    #cartTable {
        td {
            text-align: center;

            &.orderId {
                font-weight: $f-weight-700;
            }

            &.state_in_lavorazione, &.state_under_processing {
                span {
                    background-color: var(--c-oil);
                    color: var(--c-corporate-1)
                }
            }

            &.state_chiuso, &.state_closed {
                span {
                    background-color: var(--c-electric);
                    color: var(--c-corporate-1)
                }
            }

            &.orderState {
                span {
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    padding: 2px 5px;
                    display: block;
                }
            }

            &.orderActions {
                vertical-align: middle;

                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    a,
                    button {
                        display: flex;
                    }

                    button {
                        border: 0;
                        padding: 0;
                        background-color: transparent;
                        position: relative;
                    }
                }
            }
        }
    }
}

// .page-template-ordini

.page-template-ordine-dettaglio {
    .box--section-1 {
        .col-1 {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .box--section-3 {
        .col-1 {
            #cartTable {
                tbody {
                    tr {
                        td.modelQnt {
                            display: revert;

                            div {
                                display: flex;
                                justify-content: center;
                                gap: 8px;

                                .inviata {
                                    padding: 0rem 0.8rem;
                                    display: inline-block;

                                    &.green {
                                        background-color: var(--c-electric);
                                    }

                                    &.orange {
                                        background-color: var(--c-oil);
                                    }

                                    &.red {
                                        background-color: var(--c-grey-light);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// .page-template-ordine-dettaglio 

#removeFromCartPopup,
#removeAllFromCartPopup,
#removeFromWishlistPopup,
#checkoutPopup,
#riepilogoPopup,
#confermaOrdinePopup,
#cloneOrderPopup,
#checkoutDisabled {

    #ecommerceModelName, #ecommerceOrderName, #checkoutDisabledTitle {
        font-family: $f-family-title;
        font-size: 3rem;
        font-weight: $f-weight-700;
        color: var(--c-accent);
        line-height: 1;
        margin: $gutter / 4 0;
        text-align: center;

        @media #{$media-sx} {
            font-size: 2rem;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.hidden {
            display: none;
        }

        .bottom {
            display: flex;
            justify-content: center;
            margin: $gutter / 2 0;

            button {
                background-color: var(--c-red);
                color: var(--c-white);
                border: 0;
                font-size: 1.4rem;
                padding: $gutter / 4;
                text-transform: uppercase;
                position: relative;
            }
        }
    }

    // .content

    .resultMessage {
        display: flex;
        justify-content: center;
        padding: 2rem 1rem;

        &.hidden {
            display: none;
        }

        span {
            padding: 0.5rem 1rem;
            color: var(--c-white);
            font-size: 1.7rem;

            &.hidden {
                display: none;
            }

            &.error {
                background-color: var(--c-error);
            }

            &.message {
                background-color: var(--c-electric);
            }
        }
    }

    .preloader {
        display: flex;
        justify-content: center;

        &.hidden {
            display: none;
        }
    }
}

#removeAllFromCartPopup,
#removeFromWishlistPopup,
#checkoutPopup,
#riepilogoPopup,
#confermaOrdinePopup,
#cloneOrderPopup,
#checkoutDisabled {
    .content {
        .text {
            font-weight: $f-weight-700;
            font-size: 2rem;
        }
    }
}

#cloneOrderPopup {
    .content {
        .text {
            display: flex;
            flex-direction: column;

            p {
                margin: 0;
            }
        }
    }
}

#divOrdineInterno {
    &.error {
        color: var(--c-error);

        input {
            background-color: var(--c-error-light);

            &::placeholder {
                color: var(--c-black);
            }
        }
    }
}

.ritornaDashboard {
    display: flex;
    justify-content: center;
    position: relative;
    border: 1px solid var(--c-corporate-1);
    font-size: 1.4rem;
    padding: $gutter / 1.5 $gutter 0 $gutter;
    transition: 0.15s all ease;

    &::before {
        width: 15px;
        height: 19px;
        position: absolute;
        top: 5px;
        content: '';
        background: url('../images/simbolo_logo.svg') no-repeat center center / contain;
    }

    &:hover {
        background-color: var(--c-grey-light);
    }
}