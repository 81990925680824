/*------------------------------------*\
    Page Template: Gutenberg
    gutenberg.php
    .page-template-gutenberg
\*------------------------------------*/

.page-template-gutenberg {
    .gutenberg_div {
        margin: 0 auto;
        max-width: $w-media-m;
    }

    .wp-block-image {
        display: flex;
    }
    .aligncenter {
        img {
            margin: 0 auto;
        }
    }

    .wp-block-buttons {
        display: flex;
        justify-content: center;

        .wp-block-button__link {
            background-color: var(--c-corporate-1);
        }
    }
}
